import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: "HomeIndex",
        meta: {
            title: "拾起卖-废品，拾起就卖",
            content: {
                keywords: "拾起卖",
                description: "",
            },
        },
        component: () => import('@/views/index')
    },
    {
        path: '/index',
        name: "HomeIndex",
        meta: {
            title: "拾起卖-废品，拾起就卖",
            content: {
                keywords: "拾起卖",
                description: "",
            },
        },
        component: () => import('@/views/index')
    },
    {
        path: '/cityrecycle',
        name: "cityrecycle",
        meta: {
            title: "废品回收-拾起卖大收场",
            content: {
                keywords: "废品回收",
                description: "",
            },
        },
        component: () => import('@/views/cityrecycle')
    },
    {
        path: '/destruct',
        name: "destruct",
        meta: {
            title: "信息载体销毁-拾起卖大收场",
            content: {
                keywords: "信息载体销毁",
                description: "",
            },
        },
        component: () => import('@/views/destruct')
    },

    {
        path: '/about',
        name: "about",
        meta: {
            title: "企业介绍-拾起卖大收场",
            content: {
                keywords: "拾起卖企业介绍",
                description: "",
            },
        },
        component: () => import('@/views/about')
    },

    {
        path: '/cooperation',
        name: "cooperation",
        meta: {
            title: "合作入驻-拾起卖大收场",
            content: {
                keywords: "",
                description: "",
            },
        },
        component: () => import('@/views/cooperation')
    },

    {
        path: '/order',
        name: "order",
        meta: {
            title: "预约下单-拾起卖大收场",
            content: {
                keywords: "",
                description: "",
            },
        },
        component: () => import('@/views/order')
    },

    {
        path: '/solidwaste',
        name: "solidwaste",
        meta: {
            title: "固废回收-拾起卖大收场",
            content: {
                keywords: "固体废弃物,固废回收,固废处置",
                description: "",
            },
        },
        component: () => import('@/views/solidwaste')
    },

    {
        path: '/inplatform',
        name: "inplatform",
        meta: {
            title: "入驻平台-拾起卖大收场",
            content: {
                keywords: "废品回收加盟",
                description: "",
            },
        },
        component: () => import('@/views/inplatform')
    },
    {
        path: '/share',
        name: "share",
        meta: {
            title: "共享经济-拾起卖大收场",
            content: {
                keywords: "废品回收加盟",
                description: "",
            },
        },
        component: () => import('@/views/share')
    },
    {
        path: '*',
        name: "NotFound",
        component: () => import('@/views/404'),

    }

]




const router = new VueRouter({
    mode: 'history',
    scrollBehavior: () => ({ x: 0, y: 0 }),
    routes
})
router.beforeEach((to, from, next) => {





    if (to.meta.title) { //判断是否有标题
        document.title = to.meta.title
    }
    if (to.meta.content) {
        let head = document.getElementsByTagName('head');
        let meta = document.createElement('meta');
        document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
        // document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
        meta.content = to.meta.content;
        head[0].appendChild(meta)
    }
    next()

})
export default router