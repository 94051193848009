import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/styles/common.styl'
import './assets/styles/animate.css'
import './assets/styles/border.css'
import './assets/styles/reset.css'
import './assets/icon/iconfont.css'
import { Toast } from 'vant';
import 'vant/lib/index.css';
import {
    get,
    post,
    postjson,
    tepost,

} from '@/http/request.js'
Vue.prototype.get = get
Vue.prototype.post = post
Vue.prototype.tepost = tepost
Vue.prototype.postjson = postjson
Vue.prototype.Toast = Toast;
Vue.config.productionTip = false
var size = function(doc, win) {

    let fn = () => {

        var width = doc.documentElement.getBoundingClientRect().width;

        var nowwidth = 1920
        if (width > 0) {

            nowwidth = 1920 * 0.75

        }

        if (width > 1200) {

            nowwidth = 1920 * 0.8
        }
        if (width > 1300) {

            nowwidth = 1920 * 0.825
        }
        if (width > 1400) {

            nowwidth = 1920 * 0.85
        }
        if (width > 1500) {

            nowwidth = 1920 * 0.875
        }
        if (width > 1600) {

            nowwidth = 1920 * 0.90
        }
        if (width > 1700) {

            nowwidth = 1920 * 0.925
        }
        if (width > 1800) {

            nowwidth = 1920 * (0.95)
        }
        if (width > 1900) {
            console.log(width)
            nowwidth = 1920
        }
        var rem = nowwidth / 10;
        doc.documentElement.style.fontSize = rem + 'px';


    }
    var tid = ''

    win.addEventListener('resize', res => {
        clearTimeout(tid);
        tid = setTimeout(fn, 100)
    })
    doc.addEventListener('DOMContentLoaded', res => {
        clearTimeout(tid);
        tid = setTimeout(fn,50)
    });
}
size(document, window)
new Vue({
    router,
    store,
    render: h => h(App),

}).$mount('#app')