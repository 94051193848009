import axios from 'axios'
import qs from 'qs'
import store from '@/store'
import { BaseUrl } from '@/http/httpurl.js'
import { Toast } from 'vant';
import 'vant/lib/index.css';


// import { getToken } from '@/utils/auth'

// create an axios instance
// alert('requestjs')
const service = axios.create({
    headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
    // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
     baseURL: BaseUrl,
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 5000 // request timeout
})
const servicete = axios.create({
    headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
    // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    // baseURL: 'http://192.168.2.234:7061',
    // baseURL: 'https://ascendas.17mine.cn',
    baseURL: BaseUrl,
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 5000 // request timeout
})
const servicejson = axios.create({
    headers: { 'Content-Type': 'application/json' },
    // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    // baseURL: 'http://192.168.2.234:7061',
    baseURL: BaseUrl,
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 5000 // request timeout
})


// request interceptor
service.interceptors.request.use(
    config => {
        if (store.getters.token) {
            config.headers['Authorization'] = 'Bearer ' + store.getters.token
        }
        config.transformRequest = function(data, header) {
            return qs.stringify(data)
        }
        return config
    },
    error => {
        // do something with request error/ for debug
        return Promise.reject(error)
    }
)
servicete.interceptors.request.use(
    config => {
        if (store.getters.token) {
            config.headers['Authorization'] = 'Bearer ' + store.getters.token
        }
        config.transformRequest = function(data, header) {
            return qs.stringify(data)
        }
        return config
    },
    error => {
        // do something with request error/ for debug
        return Promise.reject(error)
    }
)


servicejson.interceptors.request.use(
    config => {
        // do something before request is sent
        if (store.getters.token) {
            config.headers['Authorization'] = 'Bearer ' + store.getters.token
        }
        config.transformRequest = function(data, header) {
            // console.log(header)
            return data
        }
        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)
// response interceptor
service.interceptors.response.use(
    response => {
        const res = response.data
        if (res.code == 1509 || res.code == 1507 || res.code == 1508 || res.code == 1510) {
            return Promise.reject(`${res.msg}`)
            sessionStorage.setItem('infoId', '')
            // setTimeout(() => {
            //   window.location.href = '/'
            // }, 1000)
            return Promise.reject(`${res.msg}`)
        } else if (!res.code && res.code != 0) {
            return Promise.reject(`${res.msg}`)
        } else if (
            res.code == 30001 ||
            res.code == 30002 ||
            res.code == 30003 ||
            res.code == 30004 ||
            res.code == 30005
        ) {
            return Promise.reject(`${res.msg}`)
        } else if (
            res.code == 30007
        ) {
            return Promise.reject(`${res.msg}`)
        } else if (res.code !== 200) {
            if (res.msg == '参数不能为空' || res.msg == '订单ID不能为空') {} else {
                // Message({
                //   message: res.msg || 'Error',
                //   type: 'error',
                //   duration: 2 * 1000
                // })
                return Promise.reject(res.msg)
            }

            return Promise.reject(res.msg)
        } else {
            return Promise.resolve(res)
        }
    },
    error => {

        return Promise.reject(error)
    }
)

servicete.interceptors.response.use(
    response => {
        const res = response.data
        if (res.code == 1509 || res.code == 1507 || res.code == 1508 || res.code == 1510) {
            return Promise.reject(`${res.msg}`)
            sessionStorage.setItem('infoId', '')
            // setTimeout(() => {
            //   window.location.href = '/'
            // }, 1000)
            return Promise.reject(`${res.msg}`)
        } else if (!res.code && res.code != 0) {
            return Promise.reject(`${res.msg}`)
        } else if (
            res.code == 30001 ||
            res.code == 30002 ||
            res.code == 30003 ||
            res.code == 30004 ||
            res.code == 30005
        ) {
            return Promise.reject(`${res.msg}`)
        } else if (
            res.code == 30007
        ) {
            return Promise.reject(`${res.msg}`)
        } else if (res.code !== 200) {
            if (res.msg == '参数不能为空' || res.msg == '订单ID不能为空') {} else {
                // Message({
                //   message: res.msg || 'Error',
                //   type: 'error',
                //   duration: 2 * 1000
                // })
                return Promise.reject(res.msg)
            }

            return Promise.reject(res.msg)
        } else {
            return Promise.resolve(res)
        }
    },
    error => {

        return Promise.reject(error)
    }
)
servicejson.interceptors.response.use(
    response => {
        const res = response.data
        if (
            res.code == 30007
        ) {
            return Promise.reject(`${res.msg}`)
        } else if (res.code !== 200) {
            // Message({
            //   message: res.msg || 'Error',
            //   type: 'error',
            //   duration: 2 * 1000
            // })
            return Promise.reject(`${res.msg}`)
        } else {
            return res
        }
    },
    error => {
        console.log('err' + error) // for debug
        // eslint-disable-next-line no-undef
        // Message({
        //   message: error.message,
        //   type: 'error',
        //   duration: 2 * 1000
        // })
        return Promise.reject(error)
    }
)

function get(url, data = {}, loading) {
    if (loading == 1) {
        Toast.loading({
            duration: 0,
            forbidClick: true,
        });
    }
    return new Promise((resolve, reject) => {
        service
            .get(url, data)
            .then(res => {
                Toast.clear()
                resolve(res)
            })
            .catch(err => {
                Toast.clear()
                reject(err)
            })
    })
}

function post(url, data = {}, loading, success) {
    if (loading == 1) {
        Toast.loading({
            duration: 0,
            forbidClick: true,
        });
    }
    // data.user_id = data.userId||localStorage.getItem('agCode') // php
    // data.userId = data.userId||localStorage.getItem('agCode') // php
    // data.token = store.getters.token // php
    // data.infos_id = data.infoId||store.getters.pathId // php
    // data.infoId = data.infoId||store.getters.pathId // java

    return new Promise((resolve, reject) => {
        service
            .post(url, data)
            .then(res => {

                if (loading == 1) {
                 
                     Toast.clear()
                }
                if (success == 1) {
                    
                }
                resolve(res)
            })
            .catch(err => {
                if (loading == 1) {
                    Toast.clear()
                }
                console.log('#err#' + err)
                reject(err)
            })
    })
}

function tepost(url, data = {}, loading, success) {
    if (loading == 1) {
        Toast.loading({
            duration: 0,
            forbidClick: true,
        });
    }
    // data.user_id = data.userId||localStorage.getItem('agCode') // php
    // data.userId = data.userId||localStorage.getItem('agCode') // php
    // data.token = store.getters.token // php
    // data.infos_id = data.infoId||store.getters.pathId // php
    // data.infoId = data.infoId||store.getters.pathId // java

    return new Promise((resolve, reject) => {
        servicete
            .post(url, data)
            .then(res => {

                if (loading == 1) {
                    Toast.clear()
                }
                if (success == 1) {
                    // Message({
                    //   message: res.msg || '成功',
                    //   type: 'success',
                    //   duration: 2 * 1000
                    // })
                }
                resolve(res)
            })
            .catch(err => {
                if (loading == 1) {
                    Toast.clear()
                }
                console.log('#err#' + err)
                reject(err)
            })
    })
}

function postjson(url, data, loading, success) {

    if (loading == 1) {
        Toast.loading({
            duration: 0,
            forbidClick: true,
        });
    }
    let jsonn = 0 // 0为JSON
    if (typeof data === 'string') {
        try {
            var obj = JSON.parse(data)
            if (typeof obj === 'object' && obj) {
                jsonn = 0
            } else {
                jsonn = 1
            }
        } catch (e) {
            jsonn = 1
        }
    }
    var tt = ''
    if (jsonn == 0) {
        tt = JSON.parse(data)
        tt.user_id = localStorage.getItem('agCode') // php
        tt.userId = localStorage.getItem('agCode') // php
        tt.token = store.getters.token // php
        tt.infos_id = store.getters.pathId // php
        tt.infoId = store.getters.pathId // java
    } else {
        tt = data
        tt.user_id = localStorage.getItem('agCode') // php
        tt.userId = localStorage.getItem('agCode') // php
        tt.token = store.getters.token // php
        tt.infos_id = store.getters.pathId // php
        tt.infoId = store.getters.pathId // java
    }
    tt = JSON.stringify(tt)
    return new Promise((resolve, reject) => {
        servicejson
            .post(url, tt)
            .then(res => {
                if (loading == 1) {

                    Toast.clear()
                }
                if (success == 1) {
                    // Message({
                    //   message: res.msg || '成功',
                    //   type: 'success',
                    //   duration: 2 * 1000
                    // })
                }
                resolve(res)
            })
            .catch(err => {
                if (loading == 1) {
                    Toast.clear()
                }
                reject(err)
            })
    })
}




export {

    get,
    post,
    tepost,

    postjson,

}